@import "config/colors";
@import "config/fonts";
@import "config/variables";

body {
	&:before {
		content:url(../images/ft-logo-dark.svg);
		transform:scale(.15);
		transform-origin: left top;
		height:1in;
		display: block;
		margin-top:.25in;
	}
	&:after {
		display: block;
		content:attr(data-title);
		font-size:24px;
		position: absolute;
		top:.25in;
		left:2.5in;
		padding-left:.25in;
		border-left:1px solid $black;
	}
}

#page-header {
	display: none;
}

.tabs {
	header {
		display: none;
	}
	dl {
		dt {
			display: block !important;
			border-bottom:1px solid black;
			padding-bottom:1em;
			margin-bottom:1em;
			font-size:18px;
			font-weight: bold;
			text-transform: uppercase;
			margin-top:4em;
		}
		dd {
			display: block !important;
			background: none;
			padding:0;
			section {
				max-width:none;
			}
		}
	}
}

.lessonplan, .quicktrip {
	main > header {
		figure {
			width:2in;
			float: left;
			padding-right:0;
			margin:0 2em 1em 0;
		}
		section {
			width: 100%;
			float: none;
		}
		menu {
			display: none;
		}
	}
}

.grid {
	.listing {
		li {
			padding:0;
			background:none;
			width:100%;
			margin-bottom: 1em;
			padding-left:.55in;
			.thumb {
				display: none;
			}
			h2 {
				margin-bottom:0;
				line-height:1;
				margin-right:.15em;
			}
			&:before {
				display: block;
				content:"";
				float: left;
				width:.4in;
				height:.4in;
				position: absolute;
				left:0;
				top:0;
				background-size:100%;
				background-repeat:no-repeat;
				transform:scale(.15);
				transform-origin: left top;
			}
			&.type1:before { //activity
				content:url(../images/icons/activity_icon.png);
			}
			&.type2:before { //article
				content:url(../images/icons/article_icon.png);
			}
			&.type3:before { //image
				content:url(../images/icons/photo_icon.png);
			}
			&.type4:before { //video
				content:url(../images/icons/video_icon.png);
			}
			img {
				width: .4in;
				position: absolute;
				top:0;
				left:0;
			}
		}
	}
}

.grid > header {
	display: none;
}

#page-footer {
	background:none;
	color:$black;
	padding:0;
	text-align: center;
	small {
		display: block;
		width: 100%;
	}
	ul {
		display: none;
	}
}

main {
	padding:0 !important;
	max-width:none;
}

#banner {
	min-height:0 !important;
	background:none !important;
	margin-top:.25in;
	div {
		padding:0 !important;
		max-width:none !important;
		margin-left:0 !important;
		width:100% !important;
	}
	h1 {
		font-size:24px;
	}
	p {
		font-size:16px;
	}
}

.form form {
	display: none;
}